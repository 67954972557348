import type { ResponseError } from '#types';

import type { UserType } from '~/types/users';

export const useMainStore = defineStore('main', () => {
  const firebase = useFirebaseStore();
  const router = useRouter();
  const authorized = useCookie('authorized');

  const user = useState(() => ({}) as UserType);
  const isAuthorized = useState(() => false);
  const isLogout = useState(() => false);
  const notification = ref(false);

  const sexStatusWidgetDialogVisible = ref(false);

  const loaded = computed(() => !!user.value.id);

  const setUser = (value: UserType) => {
    user.value = value;
    isAuthorized.value = true;
    isLogout.value = false;
  };

  const logout = (clear = false) =>
    UserAPI.logout().then(() => {
      if (!clear) router.push('/');

      return Promise.resolve().then(() => {
        authorized.value = null;
        user.value = {} as UserType;
        isAuthorized.value = false;
        isLogout.value = true;
      });
    });

  const loadUser = async (fallback?: boolean) => {
    if (loaded.value && fallback) return;

    return UserAPI.details()
      .then(setUser)
      .catch((e: ResponseError) => {
        if (import.meta.client && e.context?.response?.status === 401)
          return logout(true);
      });
  };

  const saveWantSex = () => {
    sendMetric(MetricEvent.wantSex, { value: user.value.wantSex });
    return UserAPI.changeWantSex({ body: { wantSex: user.value.wantSex } });
  };

  const isPWA = computed(() =>
    import.meta.server
      ? false
      : navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches,
  );

  watch(
    loaded,
    (v) => {
      if (import.meta.client && v) firebase.request();
    },
    { immediate: true },
  );

  return {
    user,
    authorized,
    isAuthorized,
    isLogout,
    notification,
    sexStatusWidgetDialogVisible,
    isPWA,

    loaded,

    setUser,
    logout,
    loadUser,
    saveWantSex,
  };
});
